import { createStore } from "vuex";
import { TheNetwork } from "@/models/TheNetwork";
import NetworkPartition from "@/models/NetworkPartition";
import { GeeqUser } from "@/models/actors/GeeqUser"

const NUM_ALT_USERS = 1;
const NUM_VISIBLE_ALT_USERS = 1;

const NUM_NODES = 4;
const GEEQ_CORP_IP_ADDR = "17.32.42.101";
const GEEQ_CHAIN_NUMBER = 42;
const GEEQ_CHAIN_GEEQ_CORP_INITIAL_BALANCE = 1000;    // Initial balance, not counting the GBBs
const GEEQ_CHAIN_USER_INITIAL_BALANCE = 400;
const GEEQ_CHAIN_NODE_USER_INITIAL_BALANCE = 300;
const GEEQ_CHAIN_ALT_USER_INITIAL_BALANCE = 500;
const DEFAULT_GEEQ_CHAIN_NAME = "My Chain Name"
const GEEQ_GBB_AMOUNT = 200;  // Good Behavior Bond amount

const TIME_MULTIPLIER = 2;
//const NTB_TIME_INTERVAL = 15;
const WAIT_FOR_TX_INTERVAL = 5;    // How long to wait for UUTs before creating NTB
const WAIT_FOR_NTB_INTERVAL = 5;   // HOW long to wait for NTBs before creating HTB

function geeqUserCmpFn(a: any, b: any): number {
    const aUser = a as GeeqUser
    const bUser = b as GeeqUser

    // Always bubble primary users to the top
    if (aUser.isPrimary) {
      return -1
    }

    if (bUser.isPrimary) {
      return 1
    }

    // Use this comparison if we want to push the node users to the bottom
    if (aUser.isNodeUser) {
      return 1
    }

    if (bUser.isNodeUser) {
      return -1
    }

    // Remaining users are in random order dictated by the randomly generated public account text
    const s1 = aUser.publicAccountText
    const s2 = bUser.publicAccountText

    return s1.localeCompare(s2)
}


export default createStore({
  state: {
    // Simulation state
    initialLoad: true,

    // Fixed Settings
    numAltUsers: NUM_ALT_USERS,
    numVisibleAltUsers: NUM_VISIBLE_ALT_USERS,
    defaultAltUserName: "User",
    chainName: DEFAULT_GEEQ_CHAIN_NAME,

    // Settings
    primaryUserName: "Jane",
    nodeUserName: "Node Owner",
    numNodes: NUM_NODES,
    timeMultiplier: TIME_MULTIPLIER,
    geeqInitialBalance: GEEQ_CHAIN_GEEQ_CORP_INITIAL_BALANCE,
    userType: "Person",				   
    gBBAmount: GEEQ_GBB_AMOUNT,   // Goood Behavior Bond amount

    // Primary User Settings
    userInitialBalance: GEEQ_CHAIN_ALT_USER_INITIAL_BALANCE,

    // Non-Primary User Settings
    altUserInitialBalance: GEEQ_CHAIN_USER_INITIAL_BALANCE,

    // Node Owner User Settings
    nodeUserInitialBalance: GEEQ_CHAIN_NODE_USER_INITIAL_BALANCE,
    
    // Constants
    geeqCorpIPAddr: GEEQ_CORP_IP_ADDR,
    geeqChainNumber: GEEQ_CHAIN_NUMBER,

    // Simulation clock tick
    clockTick: 0,

    // Time between generating NTBs
//    ntbTimeInterval: NTB_TIME_INTERVAL,

    // Time interval to wait for UUTs before sending NTB to Hub
    waitForTxInterval: WAIT_FOR_TX_INTERVAL,

    // Time interval to wait for NTBs before sending HTB to non-hub nodes.
    waitForNtbInterval: WAIT_FOR_NTB_INTERVAL,

    // Geeq Users in simulation
    geeqUsers: new Array(0), // Array of GeeqUser objects

    // Geeq Nodes in simulation
    geeqNodes: new Array(0), // Array of GeeqNode objects

    // Network paritions
    theNetwork: new TheNetwork(), // Singleton object of TheNetwork class
  },
  mutations: {
    updateInitialLoad(state) {
      state.initialLoad = false;
    },
    // Clear out network partitions
    // Clear out the users
    newNetwork(state) {
      state.theNetwork = new TheNetwork();
    },
    // Clear out the users
    clearGeeqUsers(state) {
      state.geeqUsers = [];
    },
    // Sort geeq users
    sortGeeqUsers(state) {
      state.geeqUsers.sort(geeqUserCmpFn)
    },
    // Add user
    addGeeqUser(state, newUser) {
      state.geeqUsers.push(newUser);
    },
    // Clear out the nodes
    clearGeeqNodes(state) {
      state.geeqNodes = [];
    },
    // Add user
    addGeeqNode(state, newNode) {
      state.geeqNodes.push(newNode);
    },
    // Update Clock tick
    setClockTick(state, n) {
      state.clockTick = n;
    },
    setChainName(state, chainName) {
      state.chainName = chainName
    },
    setPrimaryUserName(state, userName) {
      state.primaryUserName = userName;
    },
    setNumNodes(state, n) {
      state.numNodes = n;
    },
    setTimeMultiplier(state, n) {
      state.timeMultiplier = n;
    },
    setUserInitialBalance(state, n) {
      state.userInitialBalance = n;
    },
    setGeeqInitialBalance(state, n) {
      state.geeqInitialBalance = n;
    },
	setUserType(state, userType) {
      state.userType = userType;
    },							  
  },
  actions: {},
  modules: {},
});
