<template>
  <div class="card-warp">
    <slot></slot>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>