import { NetworkPartition } from "@/models/NetworkPartition";
import { Actor } from "@/models/Actor";
import { GeeqNode } from "./actors/GeeqNode";

// Must match the NetworkType values
const BLUE_NETWORK = "Blue";
const GREEN_NETWORK = "Green";

type NetworkType = "Blue" | "Green";

enum NETWORK_API {
  NODE_PROCESS_UTB = 0,
  NODE_PROCESS_NTB = 1,
  NODE_PROCESS_HTB = 2,
}

enum API_RETURN_CODE {
  RC_200 = 200,
  RC_400 = 400,
}

/*
 * Represents a network partition where the actors are able to communicate with each other at some level.
 * The amount of communications allowed between network actors can be modified.
 */
export default class TheNetwork {
  partitionMap: Map<NetworkType, NetworkPartition>;

  constructor() {
    this.partitionMap = new Map<NetworkType, NetworkPartition>();
    this.partitionMap.set("Blue", new NetworkPartition());
    this.partitionMap.set("Green", new NetworkPartition());
  }

  addActor(networkName: NetworkType, actor: Actor) {
    const networkPartition:
      | NetworkPartition
      | undefined = this.partitionMap.get(networkName);
    if (networkPartition !== undefined) {
      networkPartition.addActor(actor);
      actor.joinNetwork(this);
    } else {
      throw "Network partition is undefined, cannot add Actor";
    }
  }

  // Look through the network partitions that contain the requesting actor.  The ordering is not defined.
  // Return the first actor that matches the target ip address.
  // (Note:  Eventually we may want to simulate bad actors hijacking an ip address)
  getNetworkActor(ipAddr: string, requestingActor: Actor): Actor | null {
    for (const [key, partition] of this.partitionMap) {
      if (partition.includesActor(requestingActor)) {
        const actor = partition.getActor(ipAddr);
        if (actor === undefined) {
          return null;
        } else {
          return actor;
        }
      }
    }
    // None found
    return null;
  }

  // Make an api call to the target actor
  apiCall(
    targetIpAddr: string,
    requestingActor: Actor,
    api: NETWORK_API,
    messageBuffer: ArrayBuffer
  ) {
    const targetActor: GeeqNode = this.getNetworkActor(
      targetIpAddr,
      requestingActor
    ) as GeeqNode;
    if (targetActor != null) {
      if (targetActor.isGeeqNode) {
        const targetGeeqNode = targetActor as GeeqNode;

        switch (api) {
          case NETWORK_API.NODE_PROCESS_UTB:
            return targetGeeqNode.apiProcessUUT(messageBuffer);
            break;
          case NETWORK_API.NODE_PROCESS_NTB:
            return targetGeeqNode.apiProcessNTB(messageBuffer);
            break;
          case NETWORK_API.NODE_PROCESS_HTB:
            return targetGeeqNode.apiProcessHTB(messageBuffer);
          default:
            console.log("Unknown api call");
        }
      } else {
        return null;
      }
    } else {
      console.log("Unknown api target");
    }
  }
}

export {
  API_RETURN_CODE,
  BLUE_NETWORK,
  GREEN_NETWORK,
  NETWORK_API,
  TheNetwork,
};
