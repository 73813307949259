import { Actor } from "@/models/Actor";

/*
 * Represents a network partition where the actors are able to communicate with each other at some level.
 * The amount of communications allowed between network actors can be modified.
 */
export default class NetworkPartition {
  actorMap: Map<string, Actor>;
  
  constructor() {
    this.actorMap = new Map<string, Actor>();
  }

  addActor(actor: Actor) {
    this.actorMap.set(actor.ipaddr,actor);
  }

  getActor(ipAddr: string) {
    return this.actorMap.get(ipAddr);
  }

  includesActor(testActor: Actor) {

    for (const [ipAddr, actor] of this.actorMap) {
        if (testActor.ipaddr == actor.ipaddr) {
            return true;
        }
    }
    return false;
  }
}

export { NetworkPartition };
