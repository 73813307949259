<template>

  <div class="header-bar">
    <div class="inner-margin header-margin">
      <div class="header-bar-left">
        <div
          class="logo-wrap"
          itemscope="itemscope"
          itemtype="https://schema.org/Organization"
        >
          <a
            href="https://geeq.io/"
            title="go to the Geeq main website"
            class="logo-link"
          >
            <img
              src="https://geeq.io//wp-content/uploads/Geeq-Typeface-Classic-Geeq-Blue.svg"
              class="geeq-blue-logo"
            />
          </a>
        </div>
        <!-- end header-logo-wrap -->
      </div>
      <!-- end header-bar-left -->

      <div class="header-bar-right">
        <div id="nav">
          <router-link to="/" id="home-nav-link">Home</router-link>
          <!-- <router-link to="/settings" id="settings-nav-link">Settings</router-link> -->
          <router-link to="/about" id="about-nav-link">About</router-link>
          <router-link to="/faq" id="faq-nav-link">FAQs</router-link>
        </div>
      </div>
      <!-- end header-bar-right -->

      <div id="app">
        <!--    <div>
              <input type="checkbox" v-model="open"/> Check me to open a portal!
              <input type="checkbox" v-model="open2"/> Check me to open a portal!
            </div>
          -->
        <!-- </WindowPortal>
              <WindowPortal :open="open2">
            <EventDetail :msg="msg2" />
          </WindowPortal>
          </WindowPortal>
              <WindowPortal :open="open2">
            <EventDetail :msg="msg2" />
          </WindowPortal> -->
      </div>
    </div>
    <!-- end inner-margin -->
  </div>
  <!-- end header-bar -->

  <router-view />
</template>

<script>
//import EventDetail from "@/components/EventDetail";
//import WindowPortal from '@/components/WindowPortal';

export default {
  name: "App",
  components: {
    //  EventDetail,
    //  WindowPortal
  },
  data() {
    return {
      msg: "Hello from another window!",
      msg2: "Hello2 from another window!",
      open: false,
      open2: false,
    };
  },
};
</script>

<style lang="scss">
#nav {
  a {
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
