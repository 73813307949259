//import 'mdb-vue-ui-kit/css/mdb.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import BaseCard from './components/BaseCard.vue';

const app = createApp(App);
app.use(store).use(router);
app.component('base-card', BaseCard)
app.mount('#app')

